import { CSSProperties, useState } from "react";
import styles from "./Task.module.scss";
import RadioGroupAnswer from "../answer/RadioGroupAnswer";
import reactStringReplace from "react-string-replace";
import { TTask } from "../../types/TaskType";
import { useCourseContext } from "../../context/courseContext";
import { AnswerTypeEnum } from "../../constants/enums";
import { useAuthContext } from "../../context/authContext";
import { MathJaxOutput } from "../mathJax/MathJaxOutput";
import { CheckedCorrect, TChecked } from "../../types/CheckedAnswerType";
import { transformBrToNHelper } from "../../helpers/transformBrToNHelper";
import { hintStyle, styleAnswer } from "../../helpers/answerStylesHelper";
import classNames from "classnames";
import AntAnswer from "../answer/AntAnswer";
import TaskEditButton from "./TaskEditButton";
import { QuestionImage } from "./QuestionImage";
import { Button } from "../../shared/ui/button";

type TTaskItemProps = {
  task: TTask;
  // forbiddenSymbols: any,
  answerList: any;
  checked?: TChecked;
  isCheckButtonClicked: boolean;
  isCorrectSolved: boolean;
};

function TaskItem(props: TTaskItemProps) {
  const {
    task,
    //      forbiddenSymbols,
    checked,
    answerList,
    isCheckButtonClicked,
    isCorrectSolved,
  } = props;
  const styledAnswer = styleAnswer(checked);
  const styledHint = hintStyle(checked);
  //   const styledErrorSymbols = errorSymbolsStyle(forbiddenSymbols);
  const link = `/admin/task/edit/${task.id}`;
  const defaultWidth = 100;
  const maxWidth = 300;
  const [inputWidth, setInputWidth] = useState(defaultWidth);

  const mask = task.answerMask || "";

  const transformedInput = () => {
    // console.dir("mask:",mask)
    let count = -1;
    return (
      <MathJaxOutput
        text={reactStringReplace(
          transformBrToNHelper(mask),
          /(%d|%s)/g,
          (_match: string, i: number) => {
            count++;
            const elementId = "id" + task.id + "_" + count;
            return (
              <div
                key={elementId}
                className={classNames(styles.RegExpAnswer, "w-25")}
              >
                <AntAnswer
                  value={answerList[elementId] || ""}
                  disabled={isCheckButtonClicked}
                  key={elementId}
                  id={elementId}
                  onChange={onChange}
                  inputwidth={inputWidth}
                />
              </div>
            );
          },
        )}
      />
    );
  };

  const simpleAnswer = (label?: string, defaultAnswer?: any) => {
    return (
      <div key={task.id} className={classNames(styles.RegExpAnswer, "w-75")}>
        <AntAnswer
          disableUnderline={true}
          value={
            answerList["id" + task.id]
              ? answerList["id" + task.id]
              : defaultAnswer || ""
          }
          disabled={isCheckButtonClicked}
          status={""}
          id={"id" + task.id}
          placeholder={label}
          onChange={onChange}
        />
      </div>
    );
  };

  const { handleChange } = useCourseContext();
  const { authInfo } = useAuthContext();

  const onChange = (e: any) => {
    handleChange(e);
    let length = e.target.value.length;
    if (length === 0) {
      setInputWidth(defaultWidth);
    }
    if (inputWidth <= maxWidth && e.target.value.length * 10 >= inputWidth) {
      setInputWidth(defaultWidth + e.target.value.length * 5);
    }
  };

  return (
    <div className={styles.InnerLayout}>
      <div className={styles.Information}>
        <QuestionImage task={task} maxWidth={maxWidth} maxHeight={400} />

        <MathJaxOutput text={transformBrToNHelper(task.question)} />

        {task.subj ? (
          <p key={task.id} className={styles.TaskCode}>
            Тема: {task.subj} (код задачи: {task.id})
            <TaskEditButton access={authInfo.access} link={link} />
            {task.isAlreadySolved}
          </p>
        ) : (
          <p className={styles.Code}>
            (код задачи: {task.id})
            {authInfo.access ? (
              <Button
                type="link"
                className={styles.EditLink}
                onClick={() => window.open(link, "_blank")}
              >
                Редактировать
              </Button>
            ) : (
              <></>
            )}
          </p>
        )}
      </div>

      <div key={"id" + task.id} className={styles.Task}>
        {task.isAlreadySolved || (!isCheckButtonClicked && isCorrectSolved) ? (
          <div className={styles.AnswerBlock}>
            <p className={styles.AlreadySolved}>Правильно решено ранее.</p>
          </div>
        ) : (
          <div
            key={"id" + task.id}
            className={styles.AnswerBlock}
            style={
              isCheckButtonClicked
                ? task.answerType
                  ? styledAnswer
                  : { visibility: "hidden" }
                : { visibility: "visible" }
            }
          >
            {(() => {
              switch (task.answerType) {
                case AnswerTypeEnum.RADIO:
                  return (
                    <RadioGroupAnswer
                      answer={answerList["id" + task.id]}
                      disabled={isCheckButtonClicked}
                      key={task.id}
                      id={"id" + task.id}
                      answerlist={task.answerMask.split(";")}
                      onChange={handleChange}
                    />
                  );

                case AnswerTypeEnum.SIMPLE:
                  return (
                    <div className={styles.AnswerArea}>
                      {simpleAnswer("Ответ")}
                    </div>
                  );

                case AnswerTypeEnum.ALG:
                case AnswerTypeEnum.REG_EXP:
                  return (
                    <div className={styles.AnswerArea}>
                      {transformedInput()}
                    </div>
                  );

                case AnswerTypeEnum.ALG2:
                  return (
                    <div className={styles.AnswerArea}>
                      {simpleAnswer("Ответ через запятую")}
                    </div>
                  );

                case AnswerTypeEnum.NO_SPACES:
                  return (
                    <div className={styles.AnswerArea}>
                      {simpleAnswer("", task.answerMask)}
                    </div>
                  );
                default: {
                  return <div className={styles.AnswerArea}></div>;
                }
              }
            })()}
          </div>
        )}
      </div>

      {isCheckButtonClicked && checked && checked.hint ? (
        <div className={styles.Hint2} style={styledHint as CSSProperties}>
          {checked ? (
            <div>
              {checked.isCorrect === CheckedCorrect.CORRECT &&
              (checked.answerImage || checked.answerBase64Image) ? (
                <img
                  src={checked.answerBase64Image}
                  alt={checked.answerImage}
                />
              ) : (
                <></>
              )}
              <p
                className={styles.Hint2}
                dangerouslySetInnerHTML={{
                  __html: checked.hint ? checked.hint.replace(/\n/g, "") : "",
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default TaskItem;
