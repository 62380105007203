export enum CheckedCorrect {
  CORRECT = "CORRECT",
  WRONG = "WRONG",
}
export type TChecked = {
  taskId: number;
  isCorrect: CheckedCorrect;
  hint: string;
  rootId?: number;
  answerImage?: string;
  answerBase64Image?: string;
};
