import styles from "./Answer.module.scss";
import classNames from "classnames";
import { AnswerTypeEnum } from "../../constants/enums";
import { Input } from "@mui/material";

function AntAnswer(props: any) {
  return (
    <div>
      <Input
        // value={val}
        bordered="true"
        autoComplete="off"
        className={classNames(
          props.type === AnswerTypeEnum.REG_EXP
            ? styles.antInputUnderlined
            : styles.antInput,
        )}
        {...props}
        style={{ width: props.inputwidth }}
      ></Input>
    </div>
  );
}

export default AntAnswer;
