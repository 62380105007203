import { TTableColumn } from "../../types/common/TableColumnType";

export const answerLkColumns: TTableColumn[] = [
  {
    id: "course_id",
    label: "Курс",
    minWidth: 50,
    align: "left",
    linkTo: "/course/",
  },
  {
    id: "site_name",
    label: "Название",
    minWidth: 50,
    align: "right",
  },
  {
    id: "task_id",
    label: "Код задачи",
    minWidth: 50,
    align: "right",
    linkTo: "/admin/task/edit/",
  },
  {
    id: "task_num",
    label: "Номер задачи",
    minWidth: 50,
    align: "right",
  },
  {
    id: "subtask_num",
    label: "Номер подзадачи",
    minWidth: 50,
    align: "right",
  },
  {
    id: "answer_count",
    label: "Ответов",
    minWidth: 50,
    align: "right",
  },
  {
    id: "answer",
    label: "Ответы",
    minWidth: 50,
    align: "right",
  },
  {
    id: "success",
    label: "Результаты",
    minWidth: 50,
    align: "right",
  },
  {
    id: "question",
    label: "Текст задач",
    minWidth: 50,
    align: "left",
  },
];

export const studyLkColumns: TTableColumn[] = [
  {
    id: "course_id",
    label: "Курс",
    minWidth: 50,
    align: "left",
    linkTo: "/course/",
  },
  {
    id: "site_name",
    label: "Название",
    minWidth: 50,
    align: "left",
  },
  {
    id: "correct_cnt",
    label: "Верно",
    minWidth: 50,
    align: "left",
  },
  {
    id: "wrongcorrect_cnt",
    label: "Верно не с первой попытки",
    minWidth: 50,
    align: "left",
  },
  {
    id: "wrong_cnt",
    label: "Неверно",
    minWidth: 50,
    align: "left",
  },
  {
    id: "no_answer_cnt",
    label: "Нет ответов",
    minWidth: 50,
    align: "left",
  },
  {
    id: "course_task_cnt",
    label: "Всего",
    minWidth: 50,
    align: "left",
  },
  {
    id: "publish_date",
    label: "Дата",
    minWidth: 50,
    align: "left",
  },
];
