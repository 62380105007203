import React from "react";
import { Route, Routes } from "react-router-dom";
import { CourseLoadTypeEnum, CourseTypeEnum } from "../../../constants/enums";
import { AuthTypeEnum } from "../../../constants/enums";
import AdminRoute from "./AdminRoute";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import ForgotPasswordPage from "../../../pages/forgotPasswordPage";

import MainPage from "../../../pages/mainPage";
import StudentsProvider from "../../../context/studentContext";
import UserInfoProvider from "../../../context/userContext";
import EditProvider from "../../../context/editContext";
import CourseProvider from "../../../context/courseContext";
import MainEgePage from "../../../pages/mainEgePage";
import FefelovaPage from "../../../pages/fefelovaPage";
import LoginPage from "../../../pages/loginPage";

import EditPage from "../../../pages/editPage";
import TasksPage from "../../../pages/tasksPage";
import CoursesPage from "../../../pages/coursesPage";

import NotFoundPage from "../../../pages/notFound";
import ConfirmEmailPage from "../../../pages/confirmEmailPage";
import LkForTeacherPage from "../../../pages/lkForTeacherPage";
import ProfilePage from "../../../pages/profilePage";

import LkInfoPage from "../../../pages/lkInfoPage";
import AnswerLkPage from "../../../pages/answerLkPage";
import FMLSecretsArticlePage from "../../../pages/articles/fMLSecretsArticlePage";
import FeaturesOfMinimathArticlePage from "../../../pages/articles/featuresOfMinimathArticlePage";
import TeachingPage from "../../../pages/teachingPage";
import ArticlesPage from "../../../pages/articlesPage";
import FeedbackPage from "../../../pages/feedbackPage";
import OurTeamPage from "../../../pages/ourTeamPage";
import ParentsPage from "../../../pages/parentsPage";
import AchievementsPage from "../../../pages/achievementsPage";
import Results2022Page from "../../../pages/achievements/results2022Page";
import Results2021Page from "../../../pages/achievements/results2021Page";
import Results2020Page from "../../../pages/achievements/results2020Page";
import BusinessOfferPage from "../../../pages/achievements/businessOfferPage";
import ChangesToLessonsPage from "../../../pages/achievements/changesToLessonsPage";
import CongratulationsAntonPage from "../../../pages/achievements/congratulationsAntonPage";
import PreparationModePage from "../../../pages/achievements/preparationModePage";
import SummerLessonsPage from "../../../pages/achievements/summerLessonsPage";
import StudentsCongratulationsPage from "../../../pages/achievements/studentsCongratulationsPage";
import IndividualLessonsPage from "../../../pages/achievements/individualLessonsPage";
import AnswersToLessonsPage from "../../../pages/achievements/answersToLessonsPage";
import FinalOlympiadStagePage from "../../../pages/articles/finalOlympiadStagePage";
import SolvingProblemArrivedPage from "../../../pages/articles/solvingProblemArrivedPage";
import FML239BestSchoolPage from "../../../pages/articles/fML239BestSchoolPage";
import HowAndWhyMathPage from "../../../pages/articles/howAndWhyMathPage";
import Answer25Page from "../../../pages/answer25Page";
import CreatePage from "../../../pages/createPage";
import PrivacyPage from "../../../pages/privacyPage";
import ResetPasswordPage from "../../../pages/resetPasswordPage";

function AppRoutes() {
  return (
    <div>
      <Routes>
        <Route
          path={"/"}
          element={
            <PublicRoute element={<PublicRoute element={<MainPage />} />} />
          }
        />
        <Route
          path={"/ege"}
          element={
            <PublicRoute element={<PublicRoute element={<MainEgePage />} />} />
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute element={<LoginPage type={AuthTypeEnum.LOGIN} />} />
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute element={<LoginPage type={AuthTypeEnum.REGISTER} />} />
          }
        />
        <Route
          path="/fefelova"
          element={<PublicRoute element={<FefelovaPage />} />}
        />
        <Route
          path="/theme/:id"
          element={
            <CourseProvider>
              <PublicRoute
                element={<TasksPage courseType={CourseLoadTypeEnum.THEME} />}
              />
            </CourseProvider>
          }
        />
        <Route
          path="/exam/:id"
          element={
            <CourseProvider>
              <PublicRoute
                element={<TasksPage courseType={CourseLoadTypeEnum.EXAM} />}
              />
            </CourseProvider>
          }
        />
        <Route
          path="/course/:id"
          element={
            <CourseProvider>
              <PublicRoute
                element={<TasksPage courseType={CourseLoadTypeEnum.ANY} />}
              />
            </CourseProvider>
          }
        />

        <Route
          path="/admin/course/:id"
          element={
            <CourseProvider>
              <PublicRoute
                element={
                  <AdminRoute>
                    <TasksPage
                      courseType={CourseLoadTypeEnum.ANY}
                      isForAdmin={true}
                    />
                  </AdminRoute>
                }
              />
            </CourseProvider>
          }
        />
        <Route
          path="/exam"
          element={
            <CourseProvider>
              <PublicRoute
                element={<CoursesPage courseType={CourseTypeEnum.EXAM} />}
              />
            </CourseProvider>
          }
        />
        <Route
          path="/theme"
          element={
            <CourseProvider>
              <PublicRoute
                element={<CoursesPage courseType={CourseTypeEnum.THEME} />}
              />
            </CourseProvider>
          }
        />
        <Route
          path="/404"
          element={<PublicRoute element={<NotFoundPage />} />}
        />
        <Route
          path="/confirm-email"
          element={<PublicRoute element={<ConfirmEmailPage />} />}
        />
        <Route
          path="/confirm-email/:token"
          element={<PublicRoute element={<ConfirmEmailPage />} />}
        />
        <Route
          path="/articles/sekrety-fml-239-ili-pochemu-litsej-pobezhdaet-moskvichej-na-olimpiadakh"
          element={<PublicRoute element={<FMLSecretsArticlePage />} />}
        />
        <Route
          path="/articles/osobennosti-trenazhera-minimath239"
          element={<PublicRoute element={<FeaturesOfMinimathArticlePage />} />}
        />
        <Route
          path="/articles/fml-239-lider-vsosch-2022"
          element={<PublicRoute element={<FinalOlympiadStagePage />} />}
        />
        <Route
          path="/articles/the-problem-is-about-those-who-arrived-earlier-and-those-who-are-late"
          element={<PublicRoute element={<SolvingProblemArrivedPage />} />}
        />
        <Route
          path="/articles/fml-239-luchshaya-shkola-rossii-mnenie-glavnogo-trenera-sbornoj-rossii-po-matematike-kirilla-sukhova"
          element={<PublicRoute element={<FML239BestSchoolPage />} />}
        />
        <Route
          path="/articles/sergej-rukshin-kak-i-zachem-uchitsya-matematike-kak-uchit-devochek"
          element={<PublicRoute element={<HowAndWhyMathPage />} />}
        />
        <Route
          path="/teaching-lessons"
          element={<PublicRoute element={<TeachingPage />} />}
        />
        <Route
          path="/articles"
          element={<PublicRoute element={<ArticlesPage />} />}
        />
        <Route
          path="/feedback"
          element={<PublicRoute element={<FeedbackPage />} />}
        />
        <Route
          path="/our-team"
          element={<PublicRoute element={<OurTeamPage />} />}
        />
        <Route
          path="/parents"
          element={<PublicRoute element={<ParentsPage />} />}
        />
        <Route
          path="/achievements"
          element={<PublicRoute element={<AchievementsPage />} />}
        />
        <Route
          path="/achievements/2022-results"
          element={<PublicRoute element={<Results2022Page />} />}
        />
        <Route
          path="/achievements/2021-results"
          element={<PublicRoute element={<Results2021Page />} />}
        />
        <Route
          path="/achievements/2020-results"
          element={<PublicRoute element={<Results2020Page />} />}
        />
        <Route
          path="/achievements/business-offer-5-grade"
          element={<PublicRoute element={<BusinessOfferPage />} />}
        />
        <Route
          path="/achievements/changes-to-individual-lessons"
          element={<PublicRoute element={<ChangesToLessonsPage />} />}
        />
        <Route
          path="/achievements/congratulations-anton-a"
          element={<PublicRoute element={<CongratulationsAntonPage />} />}
        />
        <Route
          path="/achievements/preparation-mode"
          element={<PublicRoute element={<PreparationModePage />} />}
        />
        <Route
          path="/achievements/summer-lessons"
          element={<PublicRoute element={<SummerLessonsPage />} />}
        />
        <Route
          path="/achievements/congratulations-to-all-stundents"
          element={<PublicRoute element={<StudentsCongratulationsPage />} />}
        />
        <Route
          path="/achievements/individual-lessons"
          element={<PublicRoute element={<IndividualLessonsPage />} />}
        />
        <Route
          path="/achievements/answers-to-lessons-conditions"
          element={<PublicRoute element={<AnswersToLessonsPage />} />}
        />

        <Route
          path="/admin/task/edit/:id"
          element={
            <EditProvider>
              <PublicRoute
                element={
                  <AdminRoute>
                    <EditPage table={"task"} />
                  </AdminRoute>
                }
              />
            </EditProvider>
          }
        />

        <Route
          path="/admin/create-task"
          element={
            <EditProvider>
              <PublicRoute
                element={
                  <AdminRoute>
                    <CreatePage table={"task"} />
                  </AdminRoute>
                }
              />
            </EditProvider>
          }
        />

        <Route
          path="/admin/create-course"
          element={
            <EditProvider>
              <PublicRoute
                element={
                  <AdminRoute>
                    <CreatePage table={"course"} />
                  </AdminRoute>
                }
              />
            </EditProvider>
          }
        />

        <Route
          path="/admin/course/edit/:id"
          element={
            <EditProvider>
              <PublicRoute
                element={
                  <AdminRoute>
                    <EditPage table={"course"} />~
                  </AdminRoute>
                }
              />
            </EditProvider>
          }
        />

        <Route
          path="/lk-for-teacher"
          element={
            <StudentsProvider>
              <PublicRoute
                element={
                  <AdminRoute>
                    <LkForTeacherPage />
                  </AdminRoute>
                }
              />
            </StudentsProvider>
          }
        />

        <Route
          path="/account"
          element={
            <UserInfoProvider>
              <PublicRoute
                element={
                  <ProtectedRoute>
                    <ProfilePage />
                  </ProtectedRoute>
                }
              />
            </UserInfoProvider>
          }
        />

        <Route
          path="/lk"
          element={
            <PublicRoute
              element={
                <ProtectedRoute>
                  <LkInfoPage />
                </ProtectedRoute>
              }
            />
          }
        />

        <Route
          path="/lk/:id"
          element={
            <PublicRoute
              element={
                <AdminRoute>
                  <LkInfoPage />
                </AdminRoute>
              }
            />
          }
        />

        <Route
          path="/answer-lk"
          element={<PublicRoute element={<AnswerLkPage />} />}
        />

        <Route
          path="/answer25/:id"
          element={
            <PublicRoute
              element={
                <AdminRoute>
                  <Answer25Page />
                </AdminRoute>
              }
            />
          }
        />

        <Route
          path="/privacy"
          element={<PublicRoute element={<PrivacyPage />} />}
        />

        <Route
          path="/reset/:token"
          element={<PublicRoute element={<ResetPasswordPage />} />}
        />

        <Route
          path="/forgot-password"
          element={<PublicRoute element={<ForgotPasswordPage />} />}
        />

        <Route path="*" element={<PublicRoute element={<NotFoundPage />} />} />
      </Routes>
    </div>
  );
}

export default AppRoutes;
