import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { ProfileDropDown } from "../../loginDropDown";
import { ReportDropDown } from "../../reportDropDown";
import styles from "./navBar.module.scss";
import { useAuthContext } from "../../../context/authContext";

export function NavBarNavigation(props: {
  links: { to: string; displayName: string }[];
  onClick: () => void;
}) {
  const { links, onClick } = props;
  const location = useLocation();
  const { isAuth, authInfo, studentId } = useAuthContext()
  // @ts-ignore
  const lsAuthInfo = JSON.parse<TUserAuth>(localStorage.getItem("auth"));

  const dropDownPaths = ["answer-lk", `/answer25/${studentId}`];

  const isAnyDropDownLinkOpened = dropDownPaths.some(
    (path) => location.pathname.includes(path),
  );

  return (
    <>
      <div className={styles.Navigation}>
        {links.map((link) => (
          <NavLink
            key={link.displayName}
            onClick={onClick}
            className={styles.Link}
            to={link.to}
          >
            {link.displayName}
          </NavLink>
        ))}

        {lsAuthInfo && !isNaN(Number(lsAuthInfo.id)) ? (
          <NavLink onClick={onClick} className={styles.Link} to={"/lk"}>
            Обучение
          </NavLink>
        ) : (
          <></>
        )}

        <div
          className={classNames(styles.DropDown, {
            [styles.DropDownActive]: isAnyDropDownLinkOpened,
          })}
        >
          <ReportDropDown
            buttonClassName={
              isAnyDropDownLinkOpened ? styles.DropDownButtonActive : ""
            }
            linkPathAnswerLk={dropDownPaths[0]}
            linkPathAnswer25={dropDownPaths[1]}
          />
        </div>

        {isAuth && authInfo.access === "ok" ? (
          <NavLink
            onClick={onClick}
            className={styles.Link}
            to={"/lk-for-teacher"}
          >
            Учительская
          </NavLink>
        ) : (
          <></>
        )}
      </div>

      <div className={styles.Navigation}>
        {lsAuthInfo && !isNaN(Number(lsAuthInfo.id)) ? (
          <div className={styles.dropDown}>
            <ProfileDropDown {...lsAuthInfo} />
          </div>
        ) : (
          <>
            <NavLink onClick={onClick} className={styles.Link} to={"/login"}>
              Войти
            </NavLink>

            <NavLink onClick={onClick} className={styles.Link} to={"/register"}>
              Регистрация
            </NavLink>
          </>
        )}
      </div>
    </>
  );
}
