import { Grid } from "@mui/material";
import React, { useCallback, useState } from "react";
import AnswerLkTable from "../../../components/lk/AnswerLkTable";
import AuthInput from "../../../components/login/AuthInput";
import { useAuthContext } from "../../../context/authContext";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import { Button } from "../../../shared/ui/button";
import styles from "./answerLkPage.module.scss";
import { LkService } from "../../../APIService/LkService";
import { TAnswerLkType } from "../../../types/AnswerLkType";
import { useLoadingHook } from "../../../hooks/common/Hooks";

export function AnswerLkPage() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "lk");

  const { isAuth, studentId } = useAuthContext();
  const [days, setDays] = useState<number>(30);
  const [error, setError] = useState<boolean>(false);
  const [daysToShow, setDaysToShow] = useState<number>(days);

  const handleChange = (event: any) => {
    const { value } = event.target;
    setDaysToShow(value);
    setError(value > 30 || value < 0);
  };

  const tableDataFetch = useCallback(async () => {
    const data = await LkService.getAnswerLkInfo(studentId, days);
    return data.data as TAnswerLkType[];
  }, [days, studentId]);

  const { result, pending } = useLoadingHook(tableDataFetch, days);

  const handleClick = async () => {
    setDays(daysToShow);
  };

  return (
    <div className={styles.Wrapper}>
      <h3 className={styles.Title}>
        Результат проверки ответов{" "}
        {isAuth ? "ученика " + studentId : "незарегистрированного пользователя"}{" "}
        за последние {days} дней
      </h3>

      <Grid item xs={12} sm={4} md={2}>
        <AuthInput
          size="small"
          label={"Дни"}
          value={daysToShow}
          onChange={handleChange}
        />

        {error ? (
          <p className={styles.ErrorMsg}>
            {"Введите число, меньшее 30 и большее 0"}
          </p>
        ) : (
          <p></p>
        )}
      </Grid>

      <Grid item xs={12} sm={4} md={3}>
        <Button
          loading={pending}
          disabled={error}
          styleClass={styles.RowInput}
          onClick={handleClick}
        >
          Обновить отчет
        </Button>
      </Grid>

      <p />
      {result?.length ? (
        <AnswerLkTable tableData={result} />
      ) : (
        <p>
          Ой, кажется, о вас нет статистики за указанный период! Попробуйте
          изменить период или, если Вы давно не решали задачи - самое время
          начать!{" "}
        </p>
      )}
    </div>
  );
}
