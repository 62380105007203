import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminService from "../../../APIService/AdminService";
import { useEditContext } from "../../../context/editContext";
import {
  getPageDecorations,
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import { Button } from "../../../shared/ui/button";
import Loading from "../../../shared/ui/loading/loading";
import { EditTable } from "../../../widgets/editTable";
import styles from "./editPage.module.scss";

export function EditPage(props: { table: string }) {
  const { siteNameList } = useSiteNameContext();
  const page =
    props.table === "course" ? "/admin/course/edit" : "/admin/task/edit";
  setDocumentTitle(siteNameList, page);
  const decorations = getPageDecorations(siteNameList, page);

  const params = useParams();
  const ids = params.id;

  const { editItem, setItem, handleSubmit } = useEditContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const onSubmit = async (e: any) => {
    await handleSubmit(e).then(() => setButtonLoading(false));
  };

  useEffect(() => {
    const fetchItem = async (id: number) => {
      const response =
        props.table === "task"
          ? await AdminService.getTaskById(id)
          : await AdminService.getCourseById(id);
      setItem(response.data);
    };

    fetchItem(Number(ids)).then(() => setIsLoading(false));
  }, [ids, params, props.table, setItem]);

  return (
    <>
      <div className={styles.Wrapper}>
        <h1 className={styles.Title}>{decorations?.header1 || props.table}</h1>

        <p dangerouslySetInnerHTML={{ __html: decorations?.text1 }}></p>

        <hr />

        {isLoading ? (
          <div className={styles.centeredContent}>
            <Loading />
          </div>
        ) : (
          <div className={styles.Content}>
            <EditTable
              key={props.table}
              type={props.table}
              editItem={editItem}
              editMode={"edit"}
            />

            <div className="col-sm-6">
              <Button
                loading={buttonLoading.toString()}
                variant="contained"
                onClick={onSubmit}
              >
                Сохранить
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
