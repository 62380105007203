import React from "react";
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableHead,
  // TablePagination,
} from "@mui/material";
import { TTableColumn } from "../../../types/common/TableColumnType";
import TableHeadRow from "./tableHeadMaterial";
import CustomTableRow from "./tableRowMaterial";

export type TCustomTableProps<T> = {
  list: T[];
  page: number;
  columns: TTableColumn[];
  rowsPerPage: number;
  tooltip?: string;
  linkTo?: string;
  count?: number;
  // handleChangeRowsPerPage: (e: any) => void;
  // handleChangePage: (e: any, page: any) => void;
  localPagination?: boolean;
  onClick?: (e: React.BaseSyntheticEvent) => void;
};

function CustomTableMaterial<T extends { id?: string | number }>(
  props: TCustomTableProps<T>,
) {
  const {
    list,
    page,
    rowsPerPage,
    localPagination,
    columns,
    tooltip,
    // handleChangePage,
    // handleChangeRowsPerPage,
    linkTo,
    onClick,
  } = props;
  const dataToShow = !localPagination
    ? list
    : list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableHeadRow columns={columns} />
        </TableHead>
        <TableBody>
          {dataToShow.map((row) => {
            return (
              <CustomTableRow
                key={row.id}
                component={typeof linkTo != "undefined" ? Link : undefined}
                linkTo={
                  typeof linkTo != "undefined" ? linkTo! + row.id : undefined
                }
                tooltip={tooltip}
                row={row}
                columns={columns}
                onClick={onClick}
              />
            );
          })}
        </TableBody>
      </Table>
      {/* <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={props.count || list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}

export default CustomTableMaterial;
