import { $host } from "./index";

export default class TaskService {
  static async getExam(id: string) {
    return await $host.get("exam/" + id, {
      params: { user: JSON.parse(localStorage.getItem("auth")!).id },
    });
  }
  static async getTheme(id: string) {
    return await $host.get("theme/" + id, {
      params: { user: JSON.parse(localStorage.getItem("auth")!).id },
    });
  }
  static async getCourse(id: string) {
    return await $host.get("course/" + id, {
      params: { user: JSON.parse(localStorage.getItem("auth")!).id },
    });
  }

  /**
   * Метод для получения всех задач
   * @param courseType - тип курса
   * @return {Promise<any>}
   */
  static async getAllTasks(courseType: string) {
    return await $host.get(courseType.toLowerCase());
  }

  /**
   * Метод для сохранения ответов на курс
   * @param courseType тип курса - экзамен или тема
   * @param data - данные для отправки
   * @return {Promise<any>}
   */
  static async postAnswersToCourse(data: any) {
    //console.log('posted:', data);
    return await $host.post("course/" + data.courseId, data);
  }

  /**
   * Метод для получения данных по задаче (исп. администратором)
   * @param id - айди задания
   * @return {Promise<any>}
   */
  static async getTaskById(id: number) {
    return await $host.get("task/" + id);
  }

  /**
   * Метод для обновления информации по задаче
   * @param data - обновленные данные
   * @return {Promise<any>}
   */
  static async putUpdatedTask(data: any) {
    return await $host.put("task/" + data.id, data);
  }
}
