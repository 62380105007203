import styles from "./Task.module.scss";
import TaskItem from "./TaskItem";
import { TTask } from "../../types/TaskType";

type TTaskLayerProps = {
  tasksToShow: TTask[];
  isClickedCheck: boolean;
  answersList: any;
  checkedTasks: any;
};

function TaskItemLayer(props: TTaskLayerProps) {
  const { tasksToShow, isClickedCheck, answersList, checkedTasks } = props;
  let x = 1;
  return (
    <div>
      {tasksToShow.map((task) => (
        <div className={styles.TaskContainer} key={(x++).toString()}>
          <h1 className={styles.TaskNumber}>
            {task.displayIndex! > 9
              ? !task.rootId
                ? task.displayIndex
                : ""
              : !task.rootId
                ? "0" + task.displayIndex
                : ""}
          </h1>

          <div className={styles.TaskLayer}>
            <TaskItem
              isCorrectSolved={
                checkedTasks.find((x: any) => x.taskId === task.id)
                  ?.isCorrect === "CORRECT"
              }
              answerList={answersList}
              isCheckButtonClicked={isClickedCheck}
              checked={checkedTasks.find((x: any) => x.taskId === task.id)}
              task={task}
            />

            {task.subTasks ? (
              task.subTasks.map((subtask) => (
                <TaskItem
                  key={(x++).toString()}
                  isCorrectSolved={
                    checkedTasks.find((x: any) => x.taskId === subtask.id)
                      ?.isCorrect === "CORRECT"
                  }
                  answerList={answersList}
                  isCheckButtonClicked={isClickedCheck}
                  checked={checkedTasks.find(
                    (x: any) => x.taskId === subtask.id,
                  )}
                  task={subtask}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default TaskItemLayer;
