import React from "react";
import styles from "./TeamMemberForm.module.scss";

interface ITeamMemberForm {
  name: string;
  role: string;
  description: string;
  imageSrc: string;
}

function TeamMemberForm(formProps: ITeamMemberForm) {
  const { name, role, description, imageSrc } = formProps;

  return (
    <div className={styles.Form}>
      <img
        className={styles.Image}
        width={560 / 2}
        height={600 / 2}
        src={imageSrc}
        alt=""
      />
      <h3>{name}</h3>
      <h4>{role}</h4>
      <p>{description}</p>
    </div>
  );
}

export default TeamMemberForm;
