import { $host } from "./index";
import { TPaginationArgs } from "./CourseService";

const lkApi = "/lk";

export class LkService {
  static async getStudentListInfoForLkTeacher(args: TPaginationArgs) {
    return await $host.get(lkApi + "/student-teacher", { params: args });
  }

  static async getAnswerLkInfo(id: number, days: number) {
    return await $host.get(lkApi + "/answer-lk/" + days + "/" + id);
  }

  static async getLkInfo(id: string) {
    return await $host.get(lkApi + "/lk/" + id);
  }

  static async getAnswer25Info(id: number, days: number) {
    return await $host.get(lkApi + "/answer25", { params: { id, days } });
  }
}
