import jwtDecode from "jwt-decode";
import { $host } from "./index";
import { v4 } from "uuid";

export type AuthInput = {
  email: string;
  password: string;
};

export class AuthService {
  static loginUser = async (input: AuthInput) => {
    return await $host
      .post("login", {
        username: input.email.toLowerCase(),
        password: input.password,
      })
      .then((response: any) => {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem(
          "auth",
          JSON.stringify(jwtDecode(response.data.access_token)),
        );
        return jwtDecode(response.data.access_token);
      });
  };
  static logout = () => {
    localStorage.removeItem("token");
    localStorage.setItem("auth", '{"id": "' + v4() + '"}');
  };

  static register = async (input: AuthInput) => {
    return await $host
      .post("user", {
        host: window.location.host,
        email: input.email.toLowerCase(),
        password: input.password,
      })
      .then((response: any) => {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem(
          "auth",
          JSON.stringify(jwtDecode(response.data.access_token)),
        );
        return jwtDecode(response.data.access_token);
      });
  };

  static confirmEmail = async (token: string) => {
    return await $host.post("user/confirm", { token }).then();
  };

  static forgotPassword = async (email: string) => {
    return await $host
      .post("user/send-reset-password-mail", {
        email,
        host: window.location.host,
      })
      .then(() => {});
  };

  static changePassword = async (token: string, newPassword: string) => {
    return await $host
      .put("user/reset-password", {
        passwordResetToken: token,
        password: newPassword,
      })
      .catch((e: any) => console.log(e))
      .then(() => {});
  };
}
