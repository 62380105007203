import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import styles from "./AuthInput.module.scss";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#EB5E70",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#EB5E70",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EB5E70",
    },
    "&:hover fieldset": {
      borderColor: "#EB5E70",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EB5E70",
    },
  },
});

function AuthInput(props: any) {
  return <CssTextField className={styles.AuthInput} {...props} />;
}
export default AuthInput;
