import ReactDOM from "react-dom/client";
import "./app/styles/index.scss";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./context/authContext";
import { SnackbarProvider } from "notistack";
import SiteNameProvider from "./context/siteNameContext";
import { v4 } from "uuid";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

console.log(
  "============================= started =============================",
);
console.dir(process.env, { depth: null });
if (!localStorage.getItem("auth")) {
  localStorage.setItem("auth", '{"id": "' + v4() + '"}');
}

root.render(
  <AuthProvider>
    <SnackbarProvider maxSnack={3}>
      <SiteNameProvider>
          <App />
      </SiteNameProvider>
    </SnackbarProvider>,
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
