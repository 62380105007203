import { TChecked } from "../types/CheckedAnswerType";

export const styleAnswer = (checked?: TChecked) => {
  return checked
    ? {
        backgroundColor:
          checked.isCorrect === "WRONG"
            ? "rgba(250, 185, 185, 0.3) "
            : "rgba(75, 236, 100, 0.3)",
      }
    : {};
};
export const hintStyle = (checked?: TChecked) => {
  return checked
    ? {
        visibility: checked.hint ? "visible" : "hidden",
        backgroundColor:
          checked.isCorrect === "CORRECT"
            ? "rgba(75, 236, 100, 0.3)"
            : "lightyellow",
        paddingLeft: "0.75em",
      }
    : {};
};

export const errorSymbolsStyle = (forbiddenSymbols?: any) => {
  return forbiddenSymbols
    ? {
        border: !forbiddenSymbols.correct ? "red solid thin" : "",
      }
    : {};
};
