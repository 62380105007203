import { useEffect, useState } from "react";
import Logo from "../../../shared/ui/logo/logo";
import { Toggler } from "../../../shared/ui/toggler";
import { NavBarNavigation } from "./navBarNavigation";
import styles from "./navBar.module.scss";
import { useAuthContext } from "../../../context/authContext";

const links = [
  {
    to: "/",
    displayName: "Главная",
  },
  {
    to: "/exam",
    displayName: "Экзамены",
  },
  {
    to: "/theme",
    displayName: "Олимпиады",
  },
];

export function NavBar() {
  const { isAuth } = useAuthContext();
  const [isDropDownToggled, toggleDropDown] = useState(false);

  useEffect(() => {}, [isAuth]);

  return (
    <div className={styles.NavBar}>
      <Logo className={styles.Logo} />

      <div className={styles.MobileDropDown}>
        <Logo />
        <div className={styles.MobileDropDown}>
          {isDropDownToggled ? (
            <NavBarNavigation
              links={links}
              onClick={() => toggleDropDown(!isDropDownToggled)}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className={styles.MobileNavigation}>
        <Toggler onClick={() => toggleDropDown(!isDropDownToggled)} />
      </div>

      <div className={styles.DesktopNavigation}>
        <NavBarNavigation
          links={links}
          onClick={() => toggleDropDown(!isDropDownToggled)}
        />
      </div>
    </div>
  );
}
