import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseService from "../../../APIService/CourseService";
import TaskService from "../../../APIService/TaskService";
import TaskList from "../../../components/task/TaskList";
import { CourseLoadTypeEnum, CourseTypeEnum } from "../../../constants/enums";
import { useCourseContext } from "../../../context/courseContext";
import Loading from "../../../shared/ui/loading/loading";
import styles from "./tasksPage.module.scss";

export function TasksPage(props: {
  courseType: CourseLoadTypeEnum;
  isForAdmin?: boolean;
}) {
  const { setCourse, course, setTaskList, taskList, setCourseIds } =
    useCourseContext();
  const params = useParams();
  // TODO: get rid of courseType, it will fallback to theme if it is not exam
  const courseType: CourseTypeEnum =
    props.courseType === CourseLoadTypeEnum.EXAM
      ? CourseTypeEnum.EXAM
      : CourseTypeEnum.THEME;
  const [isCoursesLoading, setIsCoursesLoading] = useState<boolean>(false);

  // document.title = course.title || "";
  useEffect(() => {
    const getExam = async (id: string) => {
      if (id) {
        const response = await TaskService.getExam(id);
        setTaskList(response.data.tasks);
        setCourse(response.data.course);
        setIsCoursesLoading(false);
      }
    };

    const getTheme = async (id: string) => {
      if (id) {
        const response = await TaskService.getTheme(id);
        setTaskList(response.data.tasks);
        setCourse(response.data.course);
        setIsCoursesLoading(false);
      }
    };

    const getCourse = async (id: string) => {
      if (id) {
        const response = await TaskService.getCourse(id);
        setTaskList(response.data.tasks);
        setCourse(response.data.course);
        setIsCoursesLoading(false);
      }
    };

    setIsCoursesLoading(true);
    setTaskList([]);
    if (props.isForAdmin) {
      getCourse(params.id!);
    } else {
      switch (props.courseType) {
        case CourseLoadTypeEnum.EXAM:
          getExam(params.id!);
          break;
        case CourseLoadTypeEnum.THEME:
          getTheme(params.id!);
          break;
        default:
          getCourse(params.id!);
      }
    }
  }, [props.isForAdmin, params.id, props.courseType, setTaskList, setCourse]);

  useEffect(() => {
    const fetchCourseIds = async (courseType: CourseTypeEnum) => {
      const response = await CourseService.getThemeIds(courseType);
      setCourseIds(response.data);
    };

    if (props.courseType !== CourseLoadTypeEnum.ANY) {
      fetchCourseIds(courseType).then();
    }
  }, [courseType, props.courseType, setCourseIds]);

  return (
    <div className={styles.Wrapper}>
      {isCoursesLoading ? (
        <div className={styles.centeredContent}>
          <Loading />
        </div>
      ) : (
        <TaskList
          isForAdmin={props.isForAdmin}
          courseType={courseType}
          tasks={taskList}
          course={course}
        />
      )}
    </div>
  );
}

export default TasksPage;
