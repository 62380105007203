import { Button } from "@mui/material";
import { setCookie } from "react-use-cookie";
import { closeSnackbar } from "notistack";

function CookieNotification() {
  const key = new Date().toString();
  const handleClick = (agreed: boolean) => {
    if (agreed) {
      setCookie("agreedToUseCookie", String(agreed));
    } else {
      sessionStorage.setItem("agreedToUseCookie", String(agreed));
    }
    closeSnackbar();
  };

  return (
    <div key={key}>
      <p style={{ lineHeight: 0.5 }}>Мы используем файлы cookie</p>
      <span>
        Продолжив работу с сайтом, вы соглашаетесь с
        <a
          style={{ fontSize: 14 }}
          target="_blank"
          rel="noreferrer"
          href={"https://minimath239.ru/privacy"}
        >
          {" "}
          Политикой обработки персональных данных{" "}
        </a>
        и Правилами пользования сайтом.
        <p />
        <Button
          sx={{
            color: "black",
            backgroundColor: "white",
            textTransform: "none",
            fontSize: 15,
            textDecoration: "underline",
          }}
          size="small"
          onClick={() => {
            handleClick(true);
          }}
        >
          Хорошо
        </Button>
      </span>
    </div>
  );
}
export default CookieNotification;
