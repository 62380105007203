import React from "react";
import styles from "./dropDown.module.scss";
import { Menu, Dropdown as MUIDropdown, MenuButton, MenuItem } from "@mui/base";
import classNames from "classnames";

export interface IDropDownProps {
  text: string;
  items: any[];
  buttonClassName?: string;
  menuClassName?: string;
}

function DropDown(props: IDropDownProps) {
  const { text, items, buttonClassName, menuClassName } = props;
  return (
    <MUIDropdown>
      <MenuButton className={classNames(styles.header, buttonClassName)}>
        {text}
      </MenuButton>

      <Menu className={classNames(styles.menu, menuClassName)}>
        {items.map((item) => (
          <MenuItem key={item.key} onClick={item.onClick}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </MUIDropdown>
  );
}

export default DropDown;
