const axios = require("axios");

export const API_URL =
  window.location.host === "test.minimath239.ru"
    ? process.env.REACT_APP_API_TEST_URL
    : process.env.REACT_APP_API_URL;

const $host = axios.create({
  baseURL: API_URL,
});

const authInterceptor = (config: any) => {
  config.headers.Authorization = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : null;
  //console.log(config)
  return config;
};

$host.interceptors.request.use(authInterceptor);

export { $host };
