import { HashLink } from "react-router-hash-link";
import { TSchool, TSchoolOutput } from "../../../types/SchoolType";

import styles from "./schoolList.module.scss";

export interface ISchoolsListProps {
  schools: TSchoolOutput[];
}

export function SchoolsList(props: ISchoolsListProps) {
  const { schools } = props;
  const limitedSchools = schools.slice(0, 10).map((school) => ({
    ...school,
    items: school.items.slice(0, 10),
  }));

  const filteredSortedSchools = limitedSchools
    .filter((x) => x.group === "СПБ" || x.group === "МОСКВА")
    .sort((a, b) => a.group.localeCompare(b.group));

  return (
    <>
      {filteredSortedSchools.map((data) => (
        <div key={data.group}>
          <p className={styles.MainText}>
            {data.group === "СПБ"
              ? "Санкт-Петербург:"
              : data.group === "МОСКВА"
                ? "Москва:"
                : data.group}
          </p>
          <ol className={styles.Table}>
            {data.items
              .filter(
                (item: TSchool) =>
                  item.shortName !== "Обучение работе с тренажером",
              )
              .map((item) => (
                <li key={item.id} className={styles.Li}>
                  <HashLink
                    className={styles.ThemeLinks}
                    to={`/exam#${item.shortName || item.name}`}
                  >
                    {item.shortName || item.name}
                  </HashLink>
                </li>
              ))}
          </ol>
        </div>
      ))}
    </>
  );
}
