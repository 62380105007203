import { Navigate, useLocation } from "react-router-dom";

function AdminRoute(props: { children: any }) {
  const location = useLocation();
  if (
    !(
      localStorage.getItem("auth") &&
      JSON.parse(localStorage.getItem("auth")!).access
    )
  ) {
    return <Navigate to="/login" replace state={{ redirectTo: location }} />;
  }

  return props.children;
}

export default AdminRoute;
