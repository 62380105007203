import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/authContext";
import DropDown from "../../../shared/ui/dropDown/dropDown";

interface IReportDropDownProps {
  linkPathAnswerLk: string;
  linkPathAnswer25: string;
  buttonClassName?: string;
  menuClassName?: string;
}

export function ReportDropDown(props: IReportDropDownProps) {
  const { linkPathAnswerLk, linkPathAnswer25, buttonClassName, menuClassName } =
    props;
  const { authInfo } = useAuthContext();

  const items = authInfo.access === "ok"
    ? [
        {
          key: "1",
          label: <Link to={linkPathAnswerLk}>Результаты</Link>,
        },
        {
          key: "2",
          label: <Link to={linkPathAnswer25}>Отчет 25</Link>,
        },
      ]
    : [
        {
          key: "1",
          label: <Link to={linkPathAnswerLk}>Результаты</Link>,
        },
      ];

  return (
    <DropDown
      buttonClassName={buttonClassName}
      menuClassName={menuClassName}
      items={items}
      text={"Отчеты"}
    />
  );
}
