import { useRef } from "react";
import styles from "./Form.module.scss";
import { Editor } from "@tinymce/tinymce-react";

function FormTiny(props: any) {
  const editorRef = useRef<any>();
  const onChange = (e: string) => {
    props.onChange(editorRef.current.id, e);
  };
  return (
    <div className={styles.Form} id={styles.indent}>
      <label className={styles.TinyLabel}>{props.title}</label>

      <div className={styles.InputHolder}>
        <Editor
          init={{
            promotion: false,
            height: 500,
            menubar: true,
            plugins: [
              "advlist",
              "autolink",
              "link",
              "image",
              "lists",
              "preview",
              "code",
            ],
            toolbar:
              "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | preview",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          id={props.id}
          value={props.value}
          onEditorChange={onChange}
          onInit={(evt, editor) => (editorRef.current = editor)}
        />
      </div>
    </div>
  );
}

export default FormTiny;
