import { TTableColumn } from "../../types/common/TableColumnType";

export const lkTeacherColumns: TTableColumn[] = [
  {
    id: "id",
    label: "ID",
    minWidth: 50,
    align: "right",
  },
  {
    id: "firstName",
    label: "Имя",
    minWidth: 50,
    align: "right",
  },
  {
    id: "lastName",
    label: "Фамилия",
    minWidth: 50,
    align: "right",
  },
  {
    id: "sysName",
    label: "Sysname",
    minWidth: 50,
    align: "right",
  },

  {
    id: "email",
    label: "email",
    minWidth: 50,
    align: "center",
  },
];
