import { API_URL } from "../../APIService";
import { TTask } from "../../types/TaskType";

interface IQuestionImageProps {
  task: TTask;
  maxWidth: number;
  maxHeight: number;
}

export function QuestionImage(props: IQuestionImageProps) {
  const { task, maxWidth, maxHeight } = props;

  if (task.questionImage || task.base64QuestionImage) {
    return (
      <img
        style={{ maxWidth, maxHeight }}
        alt={task.questionImage}
        src={API_URL + "image" + task.questionImage}
      />
    );
  }

  return <></>;
}
