import { BaseSyntheticEvent, useEffect, useState } from "react";
import { useStudentsContext } from "../../context/studentContext";
import { TStudentInfoType } from "../../types/StudentInfoType";
import { lkTeacherColumns } from "../../constants/columns/LkTeacherColumns";
import { useAuthContext } from "../../context/authContext";
import { LkService } from "../../APIService/LkService";
import CustomTableMaterial from "../../shared/ui/table/customTableMaterial";
import { RowsPerPageEnum } from "../../constants/vars/RowsPerPageOptions";
import { enqueueSnackbar } from "notistack";

function LkForTeacherTable() {
  const { isAuth, authInfo, setStudentId } = useAuthContext();
  const [page] = useState(0);
  const [rowsPerPage] = useState(RowsPerPageEnum.ALL);
  const [total, setTotal] = useState(0);

  const { studentInfoList, setUserInfoList } = useStudentsContext();
  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  useEffect(() => {
    if (isAuth && authInfo) {
      LkService.getStudentListInfoForLkTeacher({
        page,
        take: rowsPerPage,
      }).then((res) => {
        if (!total) {
          setTotal(res.data.total);
        }
        setUserInfoList(res.data.studentInfo);
      });
    }
  }, [isAuth, rowsPerPage, page, authInfo, total, setUserInfoList]);

  // const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const onClick = (e: BaseSyntheticEvent) => {
    setStudentId(e.target.id);
    enqueueSnackbar("Выбран " + e.target.id, {
      variant: "info",
      anchorOrigin: { horizontal: "right", vertical: "top" },
      autoHideDuration: 1000,
    });
  };

  return (
    <CustomTableMaterial<TStudentInfoType>
      list={studentInfoList}
      page={page}
      count={total || studentInfoList.length}
      columns={lkTeacherColumns}
      rowsPerPage={rowsPerPage}
      linkTo={"/lk/"}
      tooltip={"Чтобы выбрать ученика, нажмите на строку"}
      // handleChangeRowsPerPage={handleChangeRowsPerPage}
      // handleChangePage={handleChangePage}
      onClick={onClick}
    />
  );
}

export default LkForTeacherTable;
